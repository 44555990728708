import React from "react";

const Header = () => {
    return (
    <>
        <div className="header-container">
            <div className='menu-item'><a href='../'>Home</a></div>
            <div className='menu-item'><a href='../wallets'>Wallets</a></div>
            <div className='menu-item'><a href='../exchanges'>Exchanges</a></div>
            <div className='menu-item'><a href='../introduction'>Introduction</a></div>
        </div>
    </>
    );
};

export default Header;
