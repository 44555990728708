import React from "react";

import LogoImage from '../assets/logo.webp'

const Logo = () => {
    return (
        <div className="logo-container">
            <img src={LogoImage} className="logo" alt="Better Your Crypto Logo" />
            <div className="site-name">
                Better Your Crypto
            </div>
        </div>
    );
};

export default Logo;
