import React from "react";
import { isAndroid, isIOS, browserName, CustomView } from 'react-device-detect';

import EdgeImage from '../assets/edge.png'
import MetaMaskImage from '../assets/metamask.png'
import GooglePlayImage from '../assets/google-play.webp'

import EdgeMetaMaskImg1 from '../assets/metamask/edge/add-to-edge-button.png'
import EdgeMetaMaskImg2 from '../assets/metamask/edge/add-to-edge-button-confirm.png'
import EdgeMetaMaskImg3 from '../assets/metamask/edge/add-to-edge-get-started.png'
import EdgeMetaMaskImg4 from '../assets/metamask/edge/add-to-edge-improve-metamask.png'
import EdgeMetaMaskImg5 from '../assets/metamask/edge/add-to-edge-import-or-create.png'

const InstallMetaMaskEdge = () => {

    let isMetaMaskInstalled = false;

    // Determine if MetaMask is Installed
    if (typeof window.ethereum !== 'undefined') {
        isMetaMaskInstalled = true;
    }
      
    return (
        <>
            <CustomView condition={browserName === "Edge"}>
                <div className='page-paragraph'>
                    <CustomView condition={isMetaMaskInstalled === true}>
                        <div className='browser'>
                            <img src={EdgeImage} alt='Edge Browser Icon' />MetaMask is already installed on the Edge browser you are using.
                        </div>
                    </CustomView>
                    <CustomView condition={isMetaMaskInstalled === false}>
                        <div className='browser'>
                            <img src={EdgeImage}  alt='Edge Browser Icon'/>MetaMask is supported on the Edge browser you are using.
                        </div>
                    </CustomView>
                </div>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === false}>
                <CustomView condition={browserName === "Edge"}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on the Edge Browser</h2>

                        <CustomView condition={isAndroid === true}>
                            <div className='browser'>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=1151737063674886090&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D' target='_blank' rel='noreferrer'>
                                    <img src={GooglePlayImage} alt='Google Play Icon'/>
                                </a> 
                                Click to get MetaMask from the Play Store. 
                            </div>
                       </CustomView>

                        <CustomView condition={isIOS === true}>
                            1. Add MetaMask from the App Store.
                        </CustomView>

                        <CustomView condition={isAndroid === false && isIOS === false}>
                            1. Add the MetaMask extension to Edge.<br /><br />

                            <div className='browser'>
                                <img src={EdgeImage} alt='Edge Browser Icon'/>
                                Click &nbsp;<a href='https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US' target='_blank' rel='noreferrer'>here</a>&nbsp; 
                                to add the MetaMask browser extension to Edge.  
                            </div>

                            <br />2. Click the 'Get' Button.<br /><br />
                            <div className='full-size-image'>
                                <img src={EdgeMetaMaskImg1} alt='Edge Browser - Install MetaMask - Get'/>
                            </div>

                            <br />3. Click the 'Add extension' button on the confirmation box.<br /><br />
                            <div className='full-size-image'>
                                <img src={EdgeMetaMaskImg2} alt='Edge Browser - Install MetaMask - Add Extension'/>
                            </div>

                            <br />4. When the installation is complete, a Welcome to MetaMask window will pop up. Click on the 'Get Started' button.<br />
                            <div className='full-size-image'>
                                <img src={EdgeMetaMaskImg3} alt='Metamask Install for Edge Browser'/>
                            </div>

                            <br />5. Click on the 'I agree' or 'No thanks' button for helping improve MetaMask.<br />
                            <div className='full-size-image'>
                                <img src={EdgeMetaMaskImg4} alt='Edge Browser - Install MetaMask - Improve MetaMask'/>
                            </div>

                            <br />6. The installation is complete. You still need a wallet to use MetaMask.
                            You can either import an existing wallet or create a new wallet.<br />
                            <div className='full-size-image'>
                                <img src={EdgeMetaMaskImg5} alt='Metamask Install for Edge Browser'/>
                            </div>

                            <div className='browser'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                                Click &nbsp;<a href='../blog/create-new-metamask-wallet'>here</a>&nbsp; 
                                for how to create a new wallet in MetaMask.<br /> 
                            </div>
                            <div className='browser'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                                Click &nbsp;<a href='../blog/import-wallet-to-metamask'>here</a>&nbsp; 
                                for how to import a wallet into MetaMask.<br /> 
                            </div>
                        </CustomView>
                    </div>
                </CustomView>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === true}>
                <CustomView condition={browserName === "Edge"}>
                    <div className='page-paragraph'>
                        <h2>Setting up a MetaMask Wallet</h2>
                        <div className='browser'>
                            <a href='../blog/how-to-create-metamask-wallet'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to create a new wallet in MetaMask.<br /> 
                        </div>
                        <div className='browser'>
                            <a href='../blog/how-to-import-wallet-to-metamask'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to import a wallet into MetaMask.<br /> 
                        </div>
                    </div>
                </CustomView>
            </CustomView>
        </>
    );
};

export default InstallMetaMaskEdge;
