import React from "react";

const About = () => {
    return (
        <div className='page-container'>
            <h1>About</h1>

            <div className='header'>
                &nbsp;
            </div>

            <div className='page-paragraph'>
                <h2>Our Team</h2>
                We are a team of cryptocurrency and DeFi enthusiasts who have come 
                together to help people like us get involved in this exciting new world.
                With the growing popularity of DeFi, we saw a need for a comprehensive 
                resource that provides information, guidance, and tools for people who are
                new to this space.
            </div>

            <div className='page-paragraph'>
                <h2>Our Mission</h2>
                Our mission is to make DeFi accessible and understandable for everyone, 
                regardless of their level of technical expertise. Whether you're just 
                getting started with cryptocurrency or are a seasoned pro, we have something 
                to offer. Our website provides a wealth of information on DeFi projects, 
                exchanges, wallets, and more, so you can make informed decisions and get 
                started quickly and easily.
            </div>

            <div className='page-paragraph'>
                <h2>Our Commitment</h2>
                We are committed to providing high-quality, up-to-date information, and 
                are constantly working to improve our offerings. We believe that DeFi has 
                the potential to change the financial landscape, and we're excited to be a
                part of this movement. So come join us, and let's get involved in DeFi 
                together!
            </div>

            <div className='page-paragraph'>
                <h3>Disclosure</h3>
                Some of the links on our site are affiliate links. This means that, at zero cost 
                to you, we will earn an affiliate commission if you click through the link and finalize 
                a purchase.
            </div>

            <div className='footer'>
                &nbsp;
            </div>
        </div>
    );
};

export default About;
