import React, { useEffect} from "react";

const Blog = () => {

    useEffect(() => {
        const script = document.createElement("script");
    
        script.type='text/javascript';
        script.lang='javascript';
        script.src='//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=6abd37a5-fb69-4655-8744-36d294050eef';
        script.async = true;
    
        document.body.appendChild(script);
    }, [])

    return (
        <div className='page-container'>
            <h1>Blog Articles</h1>

            <div className='header'>
                &nbsp;
            </div>
            
            <div className='page-paragraph'>
                <h2>Wallets</h2>
                <ol className='blog-list'>
                    <li><a href='../blog/how-to-install-metamask'>How to Install MetaMask</a></li>
                    <li><a href='../blog/how-to-import-wallet-to-metamask'>How to Import a Wallet to MetaMask</a></li>
                    <li><a href='../blog/how-to-create-metamask-wallet'>How to Create a New MetaMask Wallet</a></li>
                </ol>
            </div>
            
            <div className='page-paragraph'>
                <h2>Blockchain</h2>
                <ol className='blog-list'>
                    <li><a href='../blog/introduction-to-blockchains'>Blockchain Technology: An Introduction to the Future of Digital Transactions</a></li>
                    <li><a href='../blog/the-variety-of-blockchain-networks'>The Variety of Blockchain Networks</a></li>
                </ol>
            </div>
            
            <div className='page-paragraph'>
                <h2>Investing</h2>
                <ol className='blog-list'>
                    <li><a href='../blog/introduction-to-yield-farming'>Introduction to Yield Farming</a></li>
                    <li><a href='../blog/introduction-to-staking'>Introduction to Staking</a></li>
                </ol>
            </div>
            
            <div className='page-paragraph'>
                <h2>Decentralized Finance</h2>
                <ol className='blog-list'>
                    <li><a href='../blog/defi-protocols-the-future-of-cryptocurrency-and-finance'>Defi Protocols: The Future of Cryptocurrency and Finance</a></li>
                    <li><a href='../blog/decentralized-exchanges'>Decentralized Exchanges: A Guide to the Future of Cryptocurrency Trading</a></li>
                </ol>
            </div>
            
            <div className='page-paragraph'>
                <h2>Non-Fungible Tokens (NFTs)</h2>
                <ol className='blog-list'>
                    <li><a href='../blog/introduction-to-non-fungible-tokens'>Introduction to NFTs: A Beginner's Guide</a></li>
                </ol>
            </div>

            <div className='footer'>
                &nbsp;
            </div>

            <div id="amzn-assoc-ad-6abd37a5-fb69-4655-8744-36d294050eef"></div>

        </div>
    );
};

export default Blog;
