import React from "react";
import { isIOS, browserName, CustomView } from 'react-device-detect';

import SafariImage from '../assets/safari.png'

const InstallMetaMaskSafari = () => {

    let isMetaMaskInstalled = false;

    // Determine if MetaMask is Installed
    if (typeof window.ethereum !== 'undefined') {
        isMetaMaskInstalled = true;
    }
      
    return (
        <>
            <CustomView condition={browserName === "Safari"}>
                <div className='page-paragraph'>
                    <CustomView condition={isMetaMaskInstalled === true}>
                        <div className='browser'>
                            <img src={SafariImage} alt='Safari Browser Icon' />
                            MetaMask is already installed on the Safari browser you are using.
                        </div>
                    </CustomView>
                    <CustomView condition={isMetaMaskInstalled === false}>
                        <div className='browser'>
                            <img src={SafariImage}  alt='Safari Browser Icon'/>
                            MetaMask is NOT supported on the Safari browser you are using.
                        </div>
                    </CustomView>
                </div>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === false}>
                <CustomView condition={isIOS === true}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on your iPhone</h2>

                        1. Add MetaMask from the App Store.

                    </div>
                </CustomView>
            </CustomView>
        </>
    );
};

export default InstallMetaMaskSafari;
