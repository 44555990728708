import React from "react";
import { isAndroid, isIOS, browserName, CustomView } from 'react-device-detect';

import FirefoxImage from '../assets/firefox.png'
import MetaMaskImage from '../assets/metamask.png'
import GooglePlayImage from '../assets/google-play.webp'

import FirefoxMetaMaskImg1 from '../assets/metamask/firefox/add-to-firefox-button.png'
import FirefoxMetaMaskImg2 from '../assets/metamask/firefox/add-to-firefox-button-confirm.png'
import FirefoxMetaMaskImg3 from '../assets/metamask/firefox/add-to-firefox-complete.png'
import FirefoxMetaMaskImg4 from '../assets/metamask/metamask-get-started.png'
import FirefoxMetaMaskImg5 from '../assets/metamask/metamask-help-us-improve.png'
import FirefoxMetaMaskImg6 from '../assets/metamask/metamask-import-or-create-wallet.png'

const InstallMetaMaskFirefox = () => {

    let isMetaMaskInstalled = false;

    // Determine if MetaMask is Installed
    if (typeof window.ethereum !== 'undefined') {
        isMetaMaskInstalled = true;
    }
      
    return (
        <>
            <CustomView condition={browserName === "Firefox"}>
                <div className='page-paragraph'>
                    <CustomView condition={isMetaMaskInstalled === true}>
                        <div className='browser'>
                            <img src={FirefoxImage} alt='Firefox Browser Icon' />
                            MetaMask is already installed on the Firefox browser you are using.
                        </div>
                    </CustomView>
                    <CustomView condition={isMetaMaskInstalled === false}>
                        <div className='browser'>
                            <img src={FirefoxImage}  alt='Firefox Browser Icon'/>
                            MetaMask is supported on the Firefox browser you are using.
                        </div>
                    </CustomView>
                </div>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === false}>
                <CustomView condition={browserName === "Firefox"}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on the Firefox Browser</h2>

                        <CustomView condition={isAndroid === true}>
                            <div className='browser'>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=1151737063674886090&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D' target='_blank' rel='noreferrer'>
                                    <img src={GooglePlayImage} alt='Google Play Icon'/>
                                </a> 
                                Click to get MetaMask from the Play Store. 
                            </div>
                       </CustomView>

                        <CustomView condition={isIOS === true}>
                            1. Add MetaMask from the App Store.
                        </CustomView>

                        <CustomView condition={isAndroid === false && isIOS === false}>
                            1. Add the MetaMask extension to Firefox.

                            <div className='browser'>
                                <img src={FirefoxImage} alt='Firefox Browser Icon'/>
                                Click &nbsp;<a href='https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/' target='_blank' rel='noreferrer'>here</a>&nbsp; 
                                to add the MetaMask browser extension to Firefox. Click Add to Firefox. 
                            </div>

                            <br />2. Click the 'Add to Firefox' Button.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg1} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <br />3. Click the 'Add' button on the confirmation box.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg2} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <br />4. When the installation is complete, the button text will change to 'Remove'.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg3} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <br />5. A Welcome to MetaMask window will pop up. Click on the 'Get Started' button.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg4} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <br />6. Click on the 'I agree' or 'No thanks' button for helping improve MetaMask.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg5} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <br />7. The installation is complete. You still need a wallet to use MetaMask.
                            You can either import an existing wallet or create a new wallet.<br />
                            <div className='full-size-image'>
                                <img src={FirefoxMetaMaskImg6} alt='Metamask Install for Firefox Browser'/>
                            </div>

                            <div className='browser'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                                Click &nbsp;<a href='../blog/create-new-metamask-wallet'>here</a>&nbsp; 
                                for how to create a new wallet in MetaMask.<br /> 
                            </div>
                            <div className='browser'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                                Click &nbsp;<a href='../blog/import-wallet-to-metamask'>here</a>&nbsp; 
                                for how to import a wallet into MetaMask.<br /> 
                            </div>
                        </CustomView>
                    </div>
                </CustomView>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === true}>
                <CustomView condition={browserName === "Firefox"}>
                    <div className='page-paragraph'>
                        <h2>Setting up a MetaMask Wallet</h2>
                        <div className='browser'>
                            <a href='../blog/how-to-create-metamask-wallet'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to create a new wallet in MetaMask.<br /> 
                        </div>
                        <div className='browser'>
                            <a href='../blog/how-to-import-wallet-to-metamask'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to import a wallet into MetaMask.<br /> 
                        </div>
                    </div>
                </CustomView>
            </CustomView>
        </>
    );
};

export default InstallMetaMaskFirefox;
