import React from "react";

const Contact = () => {
    return (
        <div className='page-container'>
            <h1>Contact</h1>

            <div className='header'>
                &nbsp;
            </div>

            <div className='page-paragraph'>
                <h2>Contact Us</h2>

            </div>

            <div className='footer'>
                &nbsp;
            </div>

        </div>
    );
};

export default Contact;
