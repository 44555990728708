import React from "react";

import BlockchainImage from '../assets/blog/blockchain.webp'
import BlockchainNetworksImage from '../assets/blog/blockchain-networks.webp'
import YieldFarmingImage from '../assets/blog/yield-farming.webp'
import StakingImage from '../assets/blog/staking.webp'
import DefiProtocolsImage from '../assets/blog/defi-protocols.webp'
import DecentralizedExchangesImage from '../assets/blog/decentralized-exchanges.webp'
import NonFungibleTokensImage from '../assets/blog/non-fungible-tokens.webp'
import SecurityAndPrivacyImage from '../assets/blog/security-and-privacy.webp'

const Home = () => {
    return (
        <div class="image-box-page">

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/introduction-to-blockchains">
                        <img src={BlockchainImage} aria-label="Photo by Shubham Dhage (@theshubhamdhage) on Unsplash" />
                        <div class="image-box-text">Blockchain</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/the-variety-of-blockchain-networks">
                    <img src={BlockchainNetworksImage} aria-label="Photo by Shubham Dhage (@theshubhamdhage) on Unsplash" />
                        <div class="image-box-text">Blockchain Networks</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/introduction-to-yield-farming">
                        <img src={YieldFarmingImage} aria-label="Photo by Traxer (@traxer) on Unsplash" />
                        <div class="image-box-text">Yield Farming</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/introduction-to-staking">
                        <img src={StakingImage} aria-label="Photo by Michael Förtsch (@michael_f) on Unsplash" />
                        <div class="image-box-text">Staking</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/defi-protocols-the-future-of-cryptocurrency-and-finance">
                        <img src={DefiProtocolsImage} alt=''/>
                        <div class="image-box-text">Defi Protocols</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/decentralized-exchanges">
                        <img src={DecentralizedExchangesImage} alt='' />
                        <div class="image-box-text">Decentralized Exchanges</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/introduction-to-non-fungible-tokens">
                        <img src={NonFungibleTokensImage} alt='' />
                        <div class="image-box-text">NFTs</div>
                    </a>
                </div>
            </div>

            <div class="image-box-row">
                <div class="image-box">
                    <a href="../blog/introduction-to-security-and-privacy">
                        <img src={SecurityAndPrivacyImage} alt='' />
                        <div class="image-box-text">Security and Privacy</div>
                    </a>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

    );
};

export default Home;
