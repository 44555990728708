import React from "react";

const Wallets = () => {
    return (
        <div className='page-container'>
            <h1>Wallets</h1>

            <div className='header'>
                &nbsp;
            </div>

            <div className='page-paragraph'>
                <h2>What are Wallets?</h2>
                A cryptocurrency wallet is a software program that stores private and public 
                keys and interacts with various blockchains to enable users to send and receive 
                digital currency and monitor their balance. If you want to use cryptocurrency, 
                you will need a wallet.            
            </div>
                        
            <div className='page-paragraph'>
                <h2>Why do I need a Wallet?</h2>
                When you own cryptocurrency, you do not actually own the coins themselves. 
                Instead, you own the private keys that give you access to your public cryptocurrency 
                address and allow you to use your coins. A cryptocurrency wallet is the primary 
                tool you will use to manage these keys and interact with the blockchain.
            </div>
                        
            <div className='page-paragraph'>
                <h2>Types of Wallets</h2>
                There are several types of cryptocurrency wallets, including:<br /><br />

                <h3>Software Wallets</h3>
                Software wallets are applications that you can install on your computer or 
                mobile device. They are generally the easiest to use and are free. Examples 
                of software wallets include MetaMask, Trust Wallet, and MathWallet.<br /><br />

                <h3>Hardware Wallets</h3>
                Hardware wallets are physical devices that store your private keys offline, 
                providing extra security against online threats. Examples of hardware wallets 
                include Ledger Live, Trezor, and BlockFi Wallet.<br /><br />

                <h3>Paper Wallets</h3>
                A paper wallet is a piece of paper with your private and public keys written 
                or printed on it. They are a highly secure way to store your cryptocurrency 
                because the keys are stored offline.<br /><br />

                <h3>Exchange Wallets</h3>
                Many cryptocurrency exchanges offer their own wallets for you to use. However, 
                it is generally not recommended to store large amounts of cryptocurrency in 
                exchange wallets because exchanges are a prime target for hackers.<br /><br />
            </div>
                        
            <div className='page-paragraph'>
                <h2>Security Considerations</h2>
                When choosing a cryptocurrency wallet, it is important to consider security 
                features such as two-factor authentication and proper seed phrase storage. 
                You should also take steps to secure your device, such as using a secure password 
                and regularly updating your software.
            </div>
                        
            <div className='page-paragraph'>
                It is also important to never share your private keys with anyone, and to keep 
                backup copies of your seed phrase in a secure location.
            </div>
                        
            <div className='page-paragraph'>
                In conclusion, a cryptocurrency wallet is a necessary tool for anyone who wants 
                to use digital currency. By understanding the different types of wallets available 
                and taking steps to secure your keys, you can ensure that your cryptocurrency assets 
                are properly managed and protected.
            </div>

            <div className='header'>
                &nbsp;
            </div>
        </div>
    );
};

export default Wallets;
