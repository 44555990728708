import React from "react";

const Market = () => {
    return (
        <div className='page-container'>
            <h1>Introduction</h1>

            <div className='header'>
                &nbsp;
            </div>
            
            <div className='page-paragraph'>
                <h2>The Steps to Get Started in Cryptocurrency</h2>
                Getting started with cryptocurrency can be a confusing and overwhelming experience 
                for many people, but with the right guidance, it can be a relatively straightforward 
                process. Here are the major steps you need to get started:
            </div>
            
            <div className='page-paragraph'>
                <h2>1. Research</h2>
                Before you start investing in cryptocurrency, it is important to research different 
                cryptocurrencies and understand the technology behind them. You should also understand 
                the risks involved with investing in cryptocurrency. DYOR or "Do Your Own Research" is 
                a common phrase used in the cryptocurrency community to emphasize the importance of 
                conducting independent research and analysis before making investment decisions.
            </div>
            
            <div className='page-paragraph'>
                <h2>2. Choose a Wallet</h2>
                A cryptocurrency wallet is where you will store your digital assets. There are 
                many different types of wallets to choose from, including software wallets, hardware 
                wallets, and browser extensions like MetaMask. Read our page on <a href='./Wallets'>
                wallets</a> or <a href='./blog/how-to-install-metamask'>how to install MetaMask</a> for 
                more information. 
            </div>
            
            <div className='page-paragraph'>
                <h2>3. Choose an Exchange</h2>
                An exchange is a platform that allows you to buy and sell cryptocurrencies. Some 
                popular exchanges include Coinbase, Binance, and Kraken. When choosing an exchange, 
                consider factors like security, fees, and the selection of cryptocurrencies offered.
            </div>
            
            <div className='page-paragraph'>
                <h2>4. Verify Your Identity</h2>
                Most exchanges require you to verify your identity before you can start buying and 
                selling cryptocurrency. This typically involves providing a government-issued ID and 
                proof of residency.
            </div>
            
            <div className='page-paragraph'>
                <h2>5. Add Funds</h2>
                Once you have verified your identity, you will need to add funds to your exchange 
                account in order to buy cryptocurrency. You can do this using a bank transfer, credit 
                card, or debit card.
            </div>
            
            <div className='page-paragraph'>
                <h2>6. Buy Cryptocurrency</h2>
                Once you have added funds to your exchange account, you can start buying cryptocurrency. 
                Simply navigate to the exchange's trading platform, select the cryptocurrency you want 
                to buy, and place an order.
            </div>
            
            <div className='page-paragraph'>
                <h2>7. Transfer to Your Wallet</h2>
                Once you have bought cryptocurrency, it is recommended that you transfer it to 
                a cryptocurrency wallet that you hold the keys to for safekeeping.
            </div>
            
            <div className='page-paragraph'>
                <h2>8. Monitor Your Investments</h2>
                Once you have your cryptocurrency stored in your wallet, it is important to monitor 
                your investments and keep track of the value of your digital assets.
            </div>
            
            <div className='page-paragraph'>
                Getting started with cryptocurrency can be a bit intimidating, but by following these 
                major steps, you can get started on the right foot. With the right research and preparation, 
                you can start building your cryptocurrency portfolio and take advantage of the many 
                opportunities in this exciting and rapidly growing market.
            </div>

            <div className='footer'>
                &nbsp;
            </div>

        </div>
    );
};

export default Market;
