import React from "react";

import MetaMaskCreateWalletImg1 from '../assets/metamask/metamask-create-wallet.png'
import MetaMaskCreateWalletImg2 from '../assets/metamask/metamask-create-set-password.png'
import MetaMaskCreateWalletImg3 from '../assets/metamask/metamask-create-watch-video.png'
import MetaMaskCreateWalletImg4 from '../assets/metamask/metamask-create-reveal-words.png'
import MetaMaskCreateWalletImg5 from '../assets/metamask/metamask-create-revealed-words.png'
import MetaMaskCreateWalletImg6 from '../assets/metamask/metamask-create-enter-words.png'
import MetaMaskCreateWalletImg7 from '../assets/metamask/metamask-create-entered-words.png'
import MetaMaskCreateWalletImg8 from '../assets/metamask/metamask-import-complete.png'

const CreateMetaMaskWallet = () => {
      
    return (
        <div className='page-container'>
            <h1>How to Create a MetaMask Wallet</h1>

            <div className='page-paragraph'>
                1. Click the 'Create a wallet' Button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg1} alt='Metamask Create Wallet'/>
                </div>

                <br />2. Enter your new password and confirm password.<br />
                3. Select the checkbox to agree you have read and agree to the terms of use.<br />
                4. Click the 'Create' button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg2} alt='Metamask Create Wallet - Set Password'/>
                </div>

                <br />5. Watch the video on securing your wallet.<br />
                6. Click the 'Next' button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg3} alt='Metamask Create Wallet - Watch Video'/>
                </div>

                <br />7. Click the box to reveal your secret words.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg4} alt='Metamask Create Wallet - Reveal Words'/>
                </div>

                <br />8. Write this 12 word phrase on a piece of paper and store in a secure location.<br />
                <b>*** Note. These words are displayed for informational purposes only.<br />
                You should never reveal the words you are given to anyone else. Doing so will allow them access to your wallet</b><br /><br />
                9. Click the 'Next' button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg5} alt='Metamask Create Wallet - Revealed Words'/>
                </div>            

                <br />10. Click on each word on the bottom in the same order they appeared on the previous screen.
                The words will be put into the top box as you click each one.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg6} alt='Metamask Create Wallet - Enter Words'/>
                </div>

                <br />11. After all of the words are selected, click the 'Confirm' button. The 'Confirm' button will
                only be enabled if the words were selected in the correct order.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg7} alt='Metamask Create Wallet - Entered Words'/>
                </div>

                <br />12. Your wallet import is now complete.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskCreateWalletImg8} alt='Metamask Create Wallet - Complete'/>
                </div>
            </div>
        </div>
    );
};

export default CreateMetaMaskWallet;
