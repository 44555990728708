import React from "react";

import InstallMetaMaskBrave from '../components/install-metamask-brave'
import InstallMetaMaskChrome from '../components/install-metamask-chrome'
import InstallMetaMaskEdge from '../components/install-metamask-edge'
import InstallMetaMaskFirefox from '../components/install-metamask-firefox'
import InstallMetaMaskOpera from '../components/install-metamask-opera'
import InstallMetaMaskSafari from '../components/install-metamask-safari'

const InstallingMetaMask = () => {
      
    return (
        <div className='page-container'>
            <h1>How to Install MetaMask</h1>

            <div className='page-paragraph'>
                Getting started with cryptocurrency can be a confusing and overwhelming experience 
                for many people, but with the right guidance, it can be a relatively straightforward 
                process. Here are the steps you need to get started based on your current device
                and the browser you are using.
            </div>

            <InstallMetaMaskBrave />
            <InstallMetaMaskChrome />
            <InstallMetaMaskEdge />
            <InstallMetaMaskFirefox />
            <InstallMetaMaskOpera />
            <InstallMetaMaskSafari />
        </div>
    );
};

export default InstallingMetaMask;
