import React from "react";
import { isAndroid, isIOS, browserName, CustomView } from 'react-device-detect';

import ChromeImage from '../assets/chrome.png'
import MetaMaskImage from '../assets/metamask.png'
import GooglePlayImage from '../assets/google-play.webp'

const InstallMetaMaskChrome = () => {

    let isBrave = false;
    let isMetaMaskInstalled = false;

    // Brave is not deteced by the react-device-detect component.
    //   Logic here is to determine if the Brave Browser is installed or not.
    if (window.navigator.brave !== undefined) {
        if (window.navigator.brave.isBrave.name === "isBrave") {
            isBrave = true;            
        } else {
            isBrave = false;
        }
    } else {
        isBrave = false;
    }

    // Determine if MetaMask is Installed
    if (typeof window.ethereum !== 'undefined') {
        isMetaMaskInstalled = true;
    }
      
    return (
        <>
            <CustomView condition={isBrave === false && browserName === "Chrome"}>
                <div className='page-paragraph'>
                    <CustomView condition={isMetaMaskInstalled === true}>
                        <div className='browser'>
                            <img src={ChromeImage} alt='Chrome Broswer Icon' />MetaMask is already installed on the Chrome browser you are using.
                        </div>
                    </CustomView>
                    <CustomView condition={isMetaMaskInstalled === false}>
                        <div className='browser'>
                            <img src={ChromeImage} alt='Chrome Browser Icon' />MetaMask is supported on the Chrome browser you are using.
                        </div>
                    </CustomView>
                </div>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === false}>
                <CustomView condition={isBrave === true && isAndroid === true}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on an Android</h2>

                        <CustomView condition={isAndroid === true}>
                            <div className='browser'>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=1151737063674886090&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D' target='_blank' rel='noreferrer'>
                                    <img src={GooglePlayImage} alt='Google Play Icon'/>
                                </a> 
                                Click to get MetaMask from the Play Store. 
                            </div>
                        </CustomView>

                    </div>
                </CustomView>

                <CustomView condition={isBrave === false && browserName === "Chrome"}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on the Brave Browser</h2>

                        <CustomView condition={isAndroid === true}>
                            <div className='browser'>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=1151737063674886090&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D' target='_blank' rel='noreferrer'>
                                    <img src={GooglePlayImage} alt='Google Play Icon'/>
                                </a> 
                                Click to get MetaMask from the Play Store. 
                            </div>
                       </CustomView>

                        <CustomView condition={isIOS === true}>
                            1. Add MetaMask from the App Store.
                        </CustomView>

                        <CustomView condition={isAndroid === false && isIOS === false}>
                            1. Add the MetaMask extension to Brave.

                            <div className='browser'>
                                <img src={ChromeImage} alt='Chrome Browser Icon' />
                                Click &nbsp;<a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn' target='_blank' rel='noreferrer'>here</a>&nbsp; 
                                to add the MetaMask browser extension to Chrome. Click Add to Chrome. 
                            </div>
                        </CustomView>
                    </div>
                </CustomView>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === true}>
                <CustomView condition={isBrave === false && browserName === "Chrome"}>
                    <div className='page-paragraph'>
                        <h2>Setting up a MetaMask Wallet</h2>
                        <div className='browser'>
                            <a href='../blog/how-to-create-metamask-wallet'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to create a new wallet in MetaMask.<br /> 
                        </div>
                        <div className='browser'>
                            <a href='../blog/how-to-import-wallet-to-metamask'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to import a wallet into MetaMask.<br /> 
                        </div>
                    </div>
                </CustomView>
            </CustomView>
        </>
    );
};

export default InstallMetaMaskChrome;
