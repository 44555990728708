import React from "react";

const Glossary = () => {
    return (
        <div className='page-container'>
            <h1>Glossary</h1>

            <div className='header'>
                &nbsp;
            </div>
            
            <div className='page-paragraph'>
                <h2>Altcoin</h2>
                An altcoin is any cryptocurrency that is not Bitcoin. Altcoin is a short form 
                for "alternative coin" and refers to any digital asset other than Bitcoin.
            </div>
            
            <div className='page-paragraph'>
                <h2>ATH</h2>
                ATH stands for "All-Time High" and refers to the highest price that a particular 
                cryptocurrency has ever reached. ATH is an important metric in the cryptocurrency 
                market, as it is used to track the overall growth and performance of a particular 
                cryptocurrency, and can also be used to indicate market sentiment and the level of 
                interest in a particular cryptocurrency.
            </div>
            
            <div className='page-paragraph'>
                <h2>Bear Market</h2>
                A bull market is a financial market characterized by rising prices and a 
                positive investor sentiment. In a bull market, most assets, such as stocks or 
                cryptocurrencies, are expected to increase in value, leading investors to buy 
                more in the hope of making a profit.
            </div>
            
            <div className='page-paragraph'>
                <h2>Blockchain</h2>
                A blockchain is a decentralized, digital ledger that records transactions across a 
                network of computers. It is used to track and record transactions of assets such as 
                cryptocurrencies. Each block in the chain contains a record of recent transactions, and 
                once a block is added to the blockchain it cannot be altered or deleted. This makes the 
                blockchain secure and transparent, as every participant in the network can view and verify 
                all transactions. The decentralized nature of blockchains eliminates the need for 
                intermediaries, such as banks, to verify transactions, allowing for more secure and 
                efficient transactions. The technology is also used in other applications, such as supply 
                chain management and voting systems.
            </div>
            
            <div className='page-paragraph'>
                <h2>Bull Market</h2>
                A bull market is a financial market characterized by rising prices and a positive 
                investor sentiment. In a bull market, most assets, such as stocks or cryptocurrencies, 
                are expected to increase in value, leading investors to buy more in the hope of making 
                a profit.
            </div>
            
            <div className='page-paragraph'>
                <h2>Cryptocurrency</h2>
                A digital or virtual currency that uses cryptography for security and operates 
                independently of a central bank. Cryptocurrencies use decentralized systems, such as 
                blockchain technology, to record transactions and manage the creation of new units.
            </div>
            
            <div className='page-paragraph'>
                <h2>Dapp</h2>
                Short for decentralized application, a type of software application that runs on a 
                blockchain or peer-to-peer network. Unlike traditional apps that are controlled by a 
                single entity, dApps are built on decentralized systems, making them more secure and 
                resistant to censorship.
            </div>
            
            <div className='page-paragraph'>
                <h2>Decentralized Finance (DeFi)</h2>
                A growing movement of financial applications built on blockchain technology, 
                enabling decentralized, peer-to-peer transactions.
            </div>
            
            <div className='page-paragraph'>
                <h2>Derivative</h2>
                A derivative is a financial contract whose value is based on the price of an underlying 
                cryptocurrency. Derivatives can be used for a variety of purposes, including speculation,
                hedging against price changes, or leveraging existing investments. Derivatives, such 
                as futures and options contracts, allow traders to take advantage of price movements 
                in the underlying cryptocurrency without actually owning the asset itself.
            </div>
            
            <div className='page-paragraph'>
                <h2>DYOR</h2>
                DYOR stands for "Do Your Own Research". It is a common phrase used in the cryptocurrency 
                community to emphasize the importance of conducting independent research and analysis 
                before making investment decisions. The goal of DYOR is to help individuals become more 
                informed and confident in their investment decisions by taking the time to understand the 
                technology, the project, the team, and the market conditions. In the volatile and rapidly 
                evolving world of cryptocurrency, it is important for investors to be proactive and take 
                responsibility for their own education and investment decisions, rather than relying solely 
                on advice from others.
            </div>
            
            <div className='page-paragraph'>
                <h2>Exchanges</h2>
                A cryptocurrency exchange is a platform that allows individuals to buy, sell, and trade 
                cryptocurrencies with other individuals or with fiat money. These exchanges typically operate 
                online, and serve as a market place for buyers and sellers to come together and transact. 
            </div>
            
            <div className='page-paragraph'>
                <h2>Fiat</h2>
                The term fiat refers to a national currency that is issued by a government and is not backed 
                by a physical commodity. Examples of fiat currencies include the US dollar, Euro, Japanese yen, 
                etc. These currencies have value because they are accepted as a medium of exchange and their 
                value is determined by the supply and demand in the market, as well as by the stability of 
                the issuing government and its economy.
            </div>
            
            <div className='page-paragraph'>
                <h2>FOMO</h2>
                Fear of missing out, a psychological phenomenon often seen in markets where 
                investors feel pressure to get in on a trend before it's too late.
            </div>
            
            <div className='page-paragraph'>
                <h2>FUD</h2>
                FUD is an acronym that stands for Fear, Uncertainty, and Doubt. FUD refers 
                to negative news, rumors, or misinformation that spreads fear, uncertainty, and 
                doubt among investors and traders. This type of negative publicity can impact 
                the price of a cryptocurrency and lead to panic selling. The goal of FUD is 
                often to manipulate the market for personal gain. It's important for cryptocurrency 
                investors to be aware of FUD and to do their own research before making investment 
                decisions.
            </div>
            
            <div className='page-paragraph'>
                <h2>Futures</h2>
                A cryptocurrency futures contract is a type of financial derivative that allows 
                traders to speculate on the future price of a cryptocurrency without actually owning 
                it. Futures contracts specify that a trader will buy or sell an asset at a set price 
                on a future date. This type of investment is considered high risk and requires a 
                significant understanding of the cryptocurrency market and its potential for price 
                fluctuations.
            </div>
            
            <div className='page-paragraph'>
                <h2>Gas</h2>
                The fee paid to compensate for the computational resources used by a blockchain 
                to process transactions.
            </div>
            
            <div className='page-paragraph'>
                <h2>Hard Fork</h2>
                A change to the underlying software of a blockchain that creates a permanent 
                divergence from the previous version of the chain.
            </div>
            
            <div className='page-paragraph'>
                <h2>HODL</h2>
                A misspelling of "hold" that became popular among cryptocurrency investors 
                as a way of saying "hold on for dear life."
            </div>
            
            <div className='page-paragraph'>
                <h2>Hash Rate</h2>
                The measurement of a miner's computational power, expressed in hashes per second.
            </div>
            
            <div className='page-paragraph'>
                <h2>KYC</h2>
                KYC stands for "Know Your Customer," and it refers to the process by which a business 
                or organization verifies the identity of its clients. KYC is a regulatory requirement that 
                requires exchanges, wallets, and other financial institutions to collect and verify the 
                personal information of their users. This information includes, but is not limited to, the 
                user's name, address, government-issued identification number, and other relevant 
                information.
            </div>  
            
            <div className='page-paragraph'>
                <h2>Liquidity</h2>
                Liquidity refers to the ease with which a specific digital asset can be bought 
                or sold in the market without significantly affecting its price. High liquidity means 
                that there is a large amount of the asset available for purchase or sale and that it 
                can be bought or sold quickly and at a price close to its market value. Low liquidity, 
                on the other hand, means that it may be difficult to find a buyer or seller for the asset, 
                or that the asset may need to be sold at a significantly discounted price in order to be 
                liquidated. 
            </div>
            
            <div className='page-paragraph'>
                <h2>Long</h2>
                "Going long" or "taking a long position" is a trading strategy where an investor 
                buys an asset with the expectation that its price will rise. 
          </div>
            
            <div className='page-paragraph'>
                <h2>Mining</h2>
                The process of using computational power to validate transactions and add new 
                blocks to a blockchain.
            </div>
            
            <div className='page-paragraph'>
                <h2>NFT</h2>
                Non-fungible token, a unique digital asset that represents ownership of a 
                specific item or piece of content.
            </div>
            
            <div className='page-paragraph'>
                <h2>Node</h2>
                A node refers to a computer or device that is connected to a blockchain network and 
                helps to validate and relay transactions across the network. Nodes play an important 
                role in maintaining the integrity and security of the blockchain, as they help to confirm
                transactions and prevent double-spending. 
            </div>
            
            <div className='page-paragraph'>
                <h2>Not Your Keys, Not Your Crypto</h2>
                "Not your keys, not your crypto" is a phrase that is commonly used in the cryptocurrency 
                community to emphasize the importance of owning and controlling one's own private keys. 
                Private keys are used to access and control cryptocurrency holdings, and in most cases, 
                if you do not have control of your private keys, you do not have control of your cryptocurrency.
            </div>
            
            <div className='page-paragraph'>
                <h2>Option</h2>
                An option is a type of financial derivative that gives the holder the right, but 
                not the obligation, to buy or sell a cryptocurrency at a specified price within a 
                specified time period. 
            </div>
            
            <div className='page-paragraph'>
                <h2>Private Key</h2>
                A cryptographic code that serves as the password for a user's cryptocurrency and 
                must be kept secure.
            </div>
            
            <div className='page-paragraph'>
                <h2>Public Key</h2>
                A cryptographic code that serves as the address for a user's cryptocurrency and 
                can be used to receive funds.
            </div>
            
            <div className='page-paragraph'>
                <h2>REKT</h2>
                Slang used in the cryptocurrency community to describe a situation when someone has 
                experienced significant financial loss due to market volatility or a bad investment 
                decision.
            </div>
            
            <div className='page-paragraph'>
                <h2>Rug Pull</h2>
                A term used in the cryptocurrency world to describe a scam in which the creators of a 
                new project or token abruptly abandon the project and take all the funds raised from 
                investors with them, "pulling the rug out from under" their feet.                 
            </div>
            
            <div className='page-paragraph'>
                <h2>Short</h2>
                "Shorting" is a trading strategy where an investor sells an asset with the expectation 
                that its price will fall, allowing them to buy it back at a lower price and realize a 
                profit. 
            </div>
            
            <div className='page-paragraph'>
                <h2>Smart Contract</h2>
                A self-executing contract with the terms of the agreement directly written 
                into lines of code on a blockchain.
            </div>
            
            <div className='page-paragraph'>
                <h2>Token</h2>
                A unit of value that represents a specific asset or utility and can be traded 
                on a blockchain.
            </div>
            
            <div className='page-paragraph'>
                <h2>Wallet</h2>
                A digital storage location for cryptocurrency, similar to a physical wallet 
                that holds cash. It allows users to send, receive, and store their crypto assets securely.                
            </div>
            
            <div className='page-paragraph'>
                <h2>Web3</h2>
                A term used to describe the next generation of decentralized web technology. It aims to 
                create a more open and secure internet by allowing users to control their personal data 
                and assets through decentralized applications (dApps) built on blockchain technology.
            </div>
            
            <div className='footer'>
                &nbsp;
            </div>

        </div>
    );
};

export default Glossary;
