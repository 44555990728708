import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Page Components
import Logo from "./components/logo";
import Header from "./components/header";
import Footer from "./components/footer";

// Header Navigation Pages
import Home from "./routes";
import Wallets from "./routes/wallets";
import Exchanges from "./routes/exchanges";
import Introduction from "./routes/introduction";

// Footer Navigation Pages
import About from "./routes/about";
import Contact from "./routes/contact";
import Blog from "./routes/blog";
import Glossary from "./routes/glossary";

// Blog Article Pages - Wallets 
import InstallingMetaMask from "./blog/how-to-install-metamask";
import ImportWalletToMetaMask from "./blog/how-to-import-wallet-to-metamask";
import CreateMetaMaskWallet from "./blog/how-to-create-metamask-wallet";

// Blog Article Pages - Blockchains 
import IntroductionToBlockchains from "./blog/introduction-to-blockchains";
import TheVarietyOfBlockchainNetworks from "./blog/the-variety-of-blockchain-networks";

// Blog Article Pages - Investing 
import IntroductionToYieldFarming from "./blog/introduction-to-yield-farming";
import IntroductionToStaking from "./blog/introduction-to-staking";

// Blog Article Pages - Decentralized Finance 
import DefiProtocolsTheFutureOfCryptoAndFinance from "./blog/defi-protocols-the-future-of-cryptocurrency-and-finance";
import DecentralizedExchanges from "./blog/decentralized-exchanges";
import IntroductionToNFTs from "./blog/introduction-to-non-fungible-tokens";
import IntroductionToSecurityAndPrivacy from "./blog/introduction-to-security-and-privacy";

function App() {

    return (
        <Router>
            <Logo />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/wallets" element={<Wallets />} />
                <Route path="/exchanges" element={<Exchanges />} />
                <Route path="/introduction" element={<Introduction />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/how-to-install-metamask" element={<InstallingMetaMask />} />
                <Route path="/blog/how-to-import-wallet-to-metamask" element={<ImportWalletToMetaMask />} />
                <Route path="/blog/how-to-create-metamask-wallet" element={<CreateMetaMaskWallet />} />
                <Route path="/blog/introduction-to-blockchains" element={<IntroductionToBlockchains />} />
                <Route path="/blog/the-variety-of-blockchain-networks" element={<TheVarietyOfBlockchainNetworks />} />
                <Route path="/blog/introduction-to-yield-farming" element={<IntroductionToYieldFarming />} />
                <Route path="/blog/introduction-to-staking" element={<IntroductionToStaking />} />
                <Route path="/blog/defi-protocols-the-future-of-cryptocurrency-and-finance" element={<DefiProtocolsTheFutureOfCryptoAndFinance />} />
                <Route path="/blog/decentralized-exchanges" element={<DecentralizedExchanges />} />
                <Route path="/blog/introduction-to-non-fungible-tokens" element={<IntroductionToNFTs />} />
                <Route path="/blog/introduction-to-security-and-privacy" element={<IntroductionToSecurityAndPrivacy />} />
                <Route path="/glossary" element={<Glossary />} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default App
