import React from "react";

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
            <div className="footer-container">
                <div><a href='../about'>About</a></div>
                <div><a href='../contact'>Contact</a></div>
                <div><a href='../blog'>Blog</a></div>
                <div><a href='../glossary'>Glossary</a></div>
            </div>
            <div className='footer-container-no-outline'>
                Copyright &copy; {year}. Better Your Crypto. All rights reserved.
            </div>
        </>
    );
};

export default Footer;
