import React from "react";
import { isAndroid, isIOS, CustomView } from 'react-device-detect';

import BraveImage from '../assets/brave.png'
import MetaMaskImage from '../assets/metamask.png'
import GooglePlayImage from '../assets/google-play.webp'

import BraveMetaMaskImg1 from '../assets/metamask/brave/add-to-brave-button.png'
import BraveMetaMaskImg2 from '../assets/metamask/brave/add-to-brave-button-confirm.png'
import BraveMetaMaskImg3 from '../assets/metamask/brave/add-to-brave-create-or-import.png'
import BraveMetaMaskImg4 from '../assets/metamask/metamask-get-started.png'
import BraveMetaMaskImg5 from '../assets/metamask/metamask-help-us-improve.png'
import BraveMetaMaskImg6 from '../assets/metamask/metamask-import-or-create-wallet.png'

const InstallMetaMaskBrave = () => {

    let isBrave = false;
    let isMetaMaskInstalled = false;

    // Brave is not deteced by the react-device-detect component.
    //   Logic here is to determine if the Brave Browser is installed or not.
    if (window.navigator.brave !== undefined) {
        if (window.navigator.brave.isBrave.name === "isBrave") {
            isBrave = true;            
        } else {
            isBrave = false;
        }
    } else {
        isBrave = false;
    }

    // Determine if MetaMask is Installed
    if (typeof window.ethereum !== 'undefined') {
        isMetaMaskInstalled = true;

        if (window.ethereum.isBraveWallet === true) {
            isMetaMaskInstalled = false;
        }
    }

    return (
        <>
            <CustomView condition={isBrave === true}>
                <div className='page-paragraph'>
                    <CustomView condition={isMetaMaskInstalled === true}>
                        <div className='browser'>
                            <img src={BraveImage} alt='Brave Broswer Icon' />MetaMask is already installed on the Brave browser you are using.
                        </div>
                    </CustomView>
                    <CustomView condition={isMetaMaskInstalled === false}>
                        <div className='browser'>
                            <img src={BraveImage} alt='Brave Broswer Icon' />MetaMask is supported on the Brave browser you are using.
                        </div>
                    </CustomView>
                </div>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === false}>
                <CustomView condition={isBrave === true && isAndroid === true}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on an Android</h2>

                        <CustomView condition={isAndroid === true}>
                            <div className='browser'>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&ref=producthunt&_branch_match_id=1151737063674886090&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz00tScxNLM7WSywo0MvJzMvWT6ooz3a1yHeztAQA%2FOIqTSQAAAA%3D' target='_blank' rel='noreferrer'>
                                    <img src={GooglePlayImage} alt='Google Play Icon'/>
                                </a> 
                                Click to get MetaMask from the Play Store. 
                            </div>
                        </CustomView>

                    </div>
                </CustomView>

                <CustomView condition={isBrave === true && isAndroid === false && isIOS === false}>
                    <div className='page-paragraph'>
                        <h2>How to install MetaMask on the Brave Browser</h2>

                        1. Add the MetaMask extension to Brave.

                        <div className='browser'>
                            <img src={BraveImage} alt='Brave Browser Icon'/>
                            Click &nbsp;<a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn' target='_blank' rel='noreferrer'>here</a>&nbsp; 
                            to add the MetaMask browser extension to Brave. Click Add to Brave. 
                        </div>

                        <br />2. Click the 'Add to Brave' Button.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg1} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <br />3. Click the 'Add' button on the confirmation box.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg2} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <br />4. When the installation is complete, the button text will change to 'Remove'.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg3} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <br />5. A Welcome to MetaMask window will pop up. Click on the 'Get Started' button.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg4} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <br />6. Click on the 'I agree' or 'No thanks' button for helping improve MetaMask.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg5} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <br />7. The installation is complete. You still need a wallet to use MetaMask.
                        You can either import an existing wallet or create a new wallet.<br />
                        <div className='full-size-image'>
                            <img src={BraveMetaMaskImg6} alt='Metamask Install for Brave Browser'/>
                        </div>

                        <div className='browser'>
                            <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            Click &nbsp;<a href='../blog/create-new-metamask-wallet'>here</a>&nbsp; 
                            for how to create a new wallet in MetaMask.<br /> 
                        </div>
                        <div className='browser'>
                            <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            Click &nbsp;<a href='../blog/import-wallet-to-metamask'>here</a>&nbsp; 
                            for how to import a wallet into MetaMask.<br /> 
                        </div>

                    </div>
                </CustomView>
            </CustomView>

            <CustomView condition={isMetaMaskInstalled === true}>
                <CustomView condition={isBrave === true}>
                    <div className='page-paragraph'>
                        <h2>Setting up a MetaMask Wallet</h2>
                        <div className='browser'>
                            <a href='../blog/how-to-create-metamask-wallet'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to create a new wallet in MetaMask.<br /> 
                        </div>
                        <div className='browser'>
                            <a href='../blog/how-to-import-wallet-to-metamask'>
                                <img src={MetaMaskImage} alt='MetaMask Icon'/>
                            </a> 
                            Click for how to import a wallet into MetaMask.<br /> 
                        </div>
                    </div>
                </CustomView>
            </CustomView>
        </>
    );
};

export default InstallMetaMaskBrave;
