import React from "react";

import MetaMaskImportWalletImg1 from '../assets/metamask/metamask-import-wallet.png'
import MetaMaskImportWalletImg2 from '../assets/metamask/metamask-import-recovery-phrase.png'
import MetaMaskImportWalletImg3 from '../assets/metamask/metamask-import-recovery-phrase.png'
import MetaMaskImportWalletImg4 from '../assets/metamask/metamask-import-set-password.png'
import MetaMaskImportWalletImg5 from '../assets/metamask/metamask-import-complete.png'

const ImportWalletToMetaMask = () => {
      
    return (
        <div className='page-container'>
            <h1>How to Import a Wallet to MetaMask</h1>

            <div className='page-paragraph'>
                1. Click the 'Import wallet' Button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskImportWalletImg1} alt='Metamask Install for Opera Browser'/>
                </div>

                <br />2. From the dropdown box, select the number of words from your recovery phrase.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;A recovery phrase will consist of either 12, 15, 18, 21, or 24 words.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskImportWalletImg2} alt='Metamask Install for Opera Browser'/>
                </div>

                <br />3. Enter all of the words from your recovery phrase into teh boxes. 
                            Theses must be entered in the correct order<br />
                <div className='full-size-image'>
                    <img src={MetaMaskImportWalletImg3} alt='Metamask Install for Opera Browser'/>
                </div>

                <br />4. Enter your new password and confirm password<br />
                <br />5. Select the checkbox to agree you have read and agree to the terms of use.<br />
                <br />6. Click on the 'Import' button.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskImportWalletImg4} alt='Metamask Install for Opera Browser'/>
                </div>

                <br />7. Your wallet import is now complete.<br />
                <div className='full-size-image'>
                    <img src={MetaMaskImportWalletImg5} alt='Metamask Install for Opera Browser'/>
                </div>
            </div>
        </div>
    );
};

export default ImportWalletToMetaMask;
